import React, { useEffect, useState } from "react";
import Seo from "../../components/_App/seo";
import Navbar from "../../components/_App/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import Partner from "../../components/Common/Partner";
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar";
import Footer from "../../components/_App/Footer";
import Layout from "../../components/_App/layout";
import Breadcrumbs from "../../components/Common/Breadcrumbs"

const parent = [
  {
    name: "Tools",
    link: "tools"
  }
];

const gstPercentOptions = [
  { value: 3, label: '3%' },
  { value: 5, label: '5%' },
  { value: 12, label: '12%' },
  { value: 18, label: '18%' },
  { value: 28, label: '28%' }
];

const NORMAL_AMOUNT = 'NORMAL_AMOUNT'
const TOTAL_AMOUNT = 'TOTAL_AMOUNT'

const GstCalculator = () => {

  const [basicAmount, setBasicAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [totalGstAmount, setTotalGstAmount] = useState(0)
  const [gstPercentage, setGstPercentage] = useState(18);
  const [totalAmount, setTotalAmount] = useState(0)
  const [gstType, setGstType] = useState(NORMAL_AMOUNT)

  function handleBasicAmount(e) {
    setGstType(NORMAL_AMOUNT);
    setBasicAmount(e.target.value);
  }

  function handleFinalAmount(e) {
    setGstType(TOTAL_AMOUNT);
    setFinalAmount(e.target.value);
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
  };

  function selectGstAmount(value) {
    setGstPercentage(value);
  }

  function calculateGST() {
    if(gstType === NORMAL_AMOUNT) {
      const gstAmount = (basicAmount * Number(gstPercentage)) / 100;
      setTotalGstAmount(gstAmount);
      setTotalAmount(Number(gstAmount) + Number(basicAmount));
      const finalTotalAmount = Number(gstAmount) + Number(basicAmount)
      setFinalAmount(finalTotalAmount.toFixed(2));
    }
    else {
      const amountWithoutGst = (finalAmount / (1 + Number(gstPercentage) / 100)).toFixed(2);
      setBasicAmount(amountWithoutGst);
      const gstAmount = finalAmount - amountWithoutGst;
      setTotalGstAmount(gstAmount)
      setTotalAmount(finalAmount);
    }
  }

  useEffect(() => {
    calculateGST()
  }, [basicAmount, gstPercentage, finalAmount])

  return (
    <Layout>
      <Seo title="Calculate GST Online Tool" />
      <Navbar />
      <div className="gst-calculator ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>GST Calculator</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle="GST Calculator" parent={parent} />
          <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 d-flex justify-content-center align-items-center">
            <div className="col order-1 order-md-1 order-lg-0 mt-4">
              <div className="d-lg-flex justify-content-center align-items-center h-100">
                <div className="">
                  <div className="py-2 ">
                    <label>Amount:</label>
                    <input
                      className="border-0 border-bottom w-100 my-2"
                      type="number"
                      id="amount"
                      value={basicAmount}
                      onClick={() => setGstType(NORMAL_AMOUNT)}
                      onChange={(e) => handleBasicAmount(e)}
                      placeholder="Enter Amount"
                    />
                  </div>
                  <div className="gst-percentage py-2">
                    <label>GST Percentage:</label>
                    <div className="percentage-boxes d-flex justify-content-between gap-2">
                      {gstPercentOptions.map((option) => {
                        const selected = option.value === Number(gstPercentage)
                        return (
                          <div
                            key={option.value}
                            className={`percentage-box border p-2 rounded cursor-pointer animate__animated animate__fadeInUp`}
                            style={{
                              backgroundColor:
                                selected ? 'blueviolet' : '',
                              color: selected ? 'white' : '',
                              cursor: 'pointer',
                            }}
                            onClick={() => selectGstAmount(option.value)}
                          >
                            {option.label}
                          </div>
                        )
                      })}
                      <input
                        className="border rounded w-25 animate__animated animate__fadeInUp"
                        type="number"
                        id="customGst"
                        placeholder="GST%"
                        value={gstPercentage}
                        onChange={(e) => setGstPercentage(e.target.value)}
                      />
                    </div>
                  </div>
                  <p className="py-2">Final Price: {formatCurrency(totalAmount)}</p>

                  <div className="py-2 ">
                    <label>Final Amount:</label>
                    <input
                      className="border-0 border-bottom w-100 my-2"
                      type="number"
                      id="amount"
                      onClick={() => setGstType(TOTAL_AMOUNT)}
                      value={finalAmount}
                      onChange={(e) => handleFinalAmount(e)}
                      placeholder="Enter Amount"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col order-0 order-md-0 order-lg-1 animate__animated animate__fadeInUp">
              <div className="d-flex justify-content-center align-items-center">
                <div className="w-100 shadow rounded-3 text-center p-4 mt-4 mt-lg-0">
                  <div className="d-flex flex-column flex-md-row justify-content-evenly">
                    <div className="text-center mb-3 mb-md-0">

                    </div>
                  </div>
                  <h6 className="text-secondary m-0 fw-Semibold pt-2">
                    Gst Value
                  </h6>
                  <input
                    type="text"
                    value={formatCurrency(totalGstAmount)}
                    className="display-3 w-100 border-0 text-center text-warning py-1 fw-bold max-width-100"
                    readOnly
                  />
                  <div className="d-flex flex-column flex-md-row justify-content-center">
                    <div className="d-flex gap-5 justify-content-evenly text-center mb-3 mb-md-0">
                      <div>
                      <span className="">Amount</span>
                      <p className="align-top fw-bold text-danger text-opacity-75">
                        {formatCurrency(basicAmount)}
                      </p>
                      </div>
                      <div>
                        <span className="">Final Amount</span>
                        <p className="align-top fw-bold text-danger text-opacity-75">
                          {formatCurrency(totalAmount)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  );
};

export default GstCalculator;
